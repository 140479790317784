@import url(https://fonts.googleapis.com/css?family=Cormorant+Garamond);
/* CSS reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Gill Sans", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #252526;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* logo animation: */
#LogoWhite {
  stroke: #18CAE6;
  stroke-dasharray: 1500;
  opacity: 0.8;
  animation: animate 5s cubic-bezier(0,0.23,1,.1);
  -webkit-animation: animate 5s cubic-bezier(0,0.23,1,.1);
  -moz-animation: animate 5s cubic-bezier(0,0.23,1,.1);
}

@-webkit-keyframes animate {
  0% {
      opacity: 0;
      fill: none;
      stroke-dashoffset: 1500;
  }

  30% {
      opacity: 0.8;
      fill: none;
      stroke-dashoffset: 1500
  }

  94% {
      opacity: 0.8;
      fill: #212224;
  }
  100% {
      opacity: 0.8;
      fill: #212224;
      stroke-dashoffset: 0
  }
}

@keyframes animate {
  0% {
      opacity: 0;
      fill: none;
      stroke-dashoffset: 1500;
  }

  30% {
      opacity: 0.8;
      fill: none;
      stroke-dashoffset: 1500
  }

  94% {
      opacity: 0.8;
      fill: #212224;
  }
  100% {
      opacity: 0.8;
      fill: #212224;
      stroke-dashoffset: 0
  }
}

.hideItem {
  display: none;
}

.card {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  top: 25%;
  left: 10%;
  /* max-width: 80%;
  max-height: 90%; */
  width: 80%;
  height: 50%;
  cursor: pointer;
  will-change: transform, opacity;
  border: 1px solid #DADADA;
  font-family: 'Cormorant Garamond' ,'Gill Sans', 'Gill Sans MT', Calibri;
  font-size: 20px;
  line-height: 1.4;
  /* font-style: italic; */
  text-shadow: 4px 4px 3px rgba(0,0,0,1);
  background-color: rgba(33, 34, 36, 0.9);
  color: rgb(189, 187, 187);
  /* font-weight: lighter; */
  font-style: italic;
  padding: 10px;
  /* text-align: center; */
  
  /* transition:  0.3s linear 0.1s; */
  /* opacity: 1; */
}


.clockHolder {
  /* display: block; */
  /* height: 50%;
  width: 70%; */
  position: relative;
  /* border: 1px solid green; */
}

.container {
  position: absolute;
  top: 10%;
  left: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
  /* height: 100%; */
  /* box-sizing: border-box; */
}

.clock {
  position: relative;
  width: 130px;
  height: 130px;
  background: url(/static/media/clockBg.489f0f5e.png);
  background-size: cover;
  /* background-position: center center; */
}

.clock::after {
  content: '';
  position: absolute;
  background-color: #292929;
  z-index: 10;
  width: 6%;
  height: 6%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 50%;
}

.hand {
  --rotation: 0;
  position: absolute;
  bottom: 50%;
  left: 50%;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  -webkit-transform: translateX(-45%) rotate( calc(var(--rotation) * 1deg) );
          transform: translateX(-45%) rotate( calc(var(--rotation) * 1deg) );
  /* transform: translateY(-10%); */
  /* transform: rotate(180deg); */
}

@media only screen and (max-width: 600px) {
  .card {
    font-size: 16px !important;
  }
  /* .clock {
    width: 200px;
    height: 200px;
  } */
  .container {
    left: 25%;
  }

}
/* .App {
  text-align: center;
  border: 1px solid blue;
  padding: 20px;
} */

.main-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #DADADA;
  min-height: 100vh;
  background-image: url(/static/media/bigmainBG.198370e4.jpg);
  /* border: 1px solid green; */
  padding-bottom: 100px;
  /* overflow: hidden; */
}

/* navbar styles */
.nav-bar {
  /* position: relative; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  /* border: 1px solid red; */
}

.nav-bar.active {
  background: #212224;
}

.pages-title {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* align-self: center; */
  /* border: 1px solid magenta; */
  width: 55%;
  margin-bottom: 10px;
  align-self: center;
  opacity: 0.8;
}

.pages-title h3 {
  font-weight: lighter;
  font-size: 23px;
  color: #DADADA;
}

.pages-title h3.current {
  color: #47CDDA;
  text-decoration: underline;
  opacity: 1;
}

.nav-bar .line {
  width: 80%;
  align-self: flex-end;
  /* transition: 0.3s;
  transition-duration: 0.4s; */



  transition: 0.3s ease-out;
  -webkit-transform:scaleY(1);
          transform:scaleY(1);
  -webkit-transform-origin:top;
          transform-origin:top
}

/* end navbar styles */

/* content styles */
.content {
  margin-top: 70px;
  display: flex;
  flex-flow: row wrap;
  width: 100vw;
  height: 60vh;
  min-height: 495px;
  justify-content: center;
  /* min-height: 60vh; */
  /* border: 1px solid blue; */
}

.back-btn {
  position: sticky;
  position: -webkit-sticky;
  top: 10%;
  left: 5%;
  color: #DADADA;
  /* display: flex; */
  /* margin-top: 60px;
  color: #DADADA;
  cursor: pointer; */
}

.content-title {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  font-weight: lighter;
  font-size: 37px;
  width: 40%;
  padding: 30px;
  margin: auto 20px;
  /* border: 1px solid lawngreen; */
}
.content-title_button {
  align-self: flex-end;
  width: 80px;
  height: 40px;
  font-size: 35px;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  border: 0.5px solid #ffffff;
  /* border-radius: 3px; */
  background-color: #212224;
  text-decoration: none;
  color: #fff;
  padding: 5px;
  padding-bottom: 10px;
  margin-top: 20px;
  margin-right: 10%;
  cursor: pointer;
  /* border: 1px solid red; */
}

.content-carousel {
  display: flex;
  flex-direction: row;
  width: 50%;
  margin: auto 10px;
  /* opacity: 0;
  transition: opacity 2s;
  opacity: 1; */
  background-color: rgba(33, 34, 36, 0.9);
  /* border: 1px solid #343538; */
  box-shadow: 4px 5px 27px -12px rgba(0,0,0,0.7);

}

.projectsName {
  font-weight: lighter;
  /* border: 1px solid brown; */
  text-align: center;
}

.carousel {
  /* border: 1px solid blue; */
  position: relative;
  /* height: 500px; */
  width: 100%;
  min-width: 400px;
}

.slide {
  /* background-color: #353639; */
  text-align: center;
  /* border: 1px solid #353639; */
  border-radius: 1%;
  cursor: grab;
}

.image {
  width: 95%;
  height: 95%;
  margin: 1.5% auto;
  opacity: 0.7;
  transition: all 0.3s linear 0.1s;
  /* border: 1px solid pink; */
}

.image:hover {
  opacity: 1;
  transition: all 0.3s linear 0.1s;
}

.title-carousel {
  text-align: center;
  font-size: 25px;
  font-weight: lighter;
  text-align: center;
  margin: 7% auto 5%;
}

.carousel-text {
  display: flex;
  justify-content: space-evenly;
  align-items: space-around;
  width: 93%;
  height: 95%;
  margin: 1.5% auto;
  padding: 20px;
  position: relative;
  opacity: 0.8;
  font-size: 23px;
  font-weight: lighter;
  text-align: justify;
  transition: all 0.2s linear 0.1s;
  /* border: 1px solid pink; */
}
.carousel-text:hover {
  opacity: 1;
  transition: all 0.3s linear 0.1s;
}

.slider-buttons_l {
  position: absolute;
  font-size: 35px;
  top: 3%;
  left: 1%;
  color: #A29E9E;
  background-color: transparent;
  border: none;
  opacity: 0.5;
  height: 95%;
  outline: none;
  /* margin-left: 5px; */
}
.slider-buttons_r {
  position: absolute;
  font-size: 35px;
  top: 3%;
  right: 1%;
  color: #A29E9E;
  background-color: transparent;
  border: none;
  opacity: 0.4;
  height: 95%;
  outline: none;
  /* margin-right: 5px; */
}

.slider-buttons_r:hover, .slider-buttons_l:hover {
  opacity: 1;
}
.dots{
  position: absolute;
  /* right: 50%; */
  width: 15%;
  border: 1px solid red;
  display: flex;
  justify-content: space-evenly;
  
}

.dots > * {
  width: 10px;
  height: 10px;
  /* border-radius: 25px; */
  /* border-radius: 50%; */
}

.content-carousel_line {
  /* border-right: 4px solid #D11414; */
  border-right: 4px solid #DF740C;
  height: 100%;
  margin: 0 auto;
}

/* .content-carousel_line-l {
  border-right: 2px solid #DF740C;
  height: 100%;
  margin: 0 0 0 auto;
}
.content-carousel_line-m {
  border-right: 1px solid #f5993d;
  height: 100%;
  margin: 0;
}
.content-carousel_line-r {
  border-right:  1px solid #DF740C;
  height: 100%;
  margin: 0 auto 0 0;
} */

/* end of content styles */

/* Footer: */

.footer {
  display: flex;
  flex-flow: row wrap;
  position: absolute;
  justify-content: space-around;
  width: 100vw;
  bottom: 0;
  min-height: 80px;
}

.footer.active {
  justify-content: flex-start;
  padding-left: 10%;
}

.footer-social {
  align-self: center;
  /* border: 1px solid yellow */
}
.icon {
  margin: 10px;
  opacity: 0.4;
  transition: 0.3s;
  color: white;
}
.icon:hover {
  -webkit-transform: rotate(-11deg);
          transform: rotate(-11deg);
  opacity: 1;
  transition-duration: 0.4s;
  cursor: pointer;
}

.footer-name {
  align-self: center;
  font-weight: lighter;
  /* font-size: 57px; */
  font-size: 5.7rem;
  opacity: 0.8;
  /* margin-bottom: 30px; */
  /* border: 1px solid yellow; */
}

/* end of Footer */

/* media queries */

@media only screen and (min-width: 801px) {
  .animate {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-name: slide;
            animation-name: slide;
  }
  @-webkit-keyframes slide {
    from {
      height: 0%;
    }
  
    to {
      height: 100%;
    }
  }
  @keyframes slide {
    from {
      height: 0%;
    }
  
    to {
      height: 100%;
    }
  }
}

@media only screen and (max-width: 850px) {
  .pages-title {
    justify-content: space-between;
  }

  .pages-title h3 {
    font-size: 18px;
    padding-right: 10px;
  }

  .back-btn {
    margin-left: 30px;
  }
  .content {
    /* height: 90vh; */
    min-height: 750px;
  }
  .content-title {
    width: 95%;
  }
  .content-carousel {
    width: 88vw;
  }
  .content-carousel_line {
    margin-top: 40px;
    height: 4px;
    border-right: 0;
    border-bottom: 4px solid #DF740C;
    width: 100%;
  }

  .footer-name {
    font-size: 3.7rem;
  }

  .image {
    width: 90%;
    height: 90%;
  }

  .animate {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-name: bounce;
            animation-name: bounce;
  }
  @-webkit-keyframes bounce{
    from {
      width: 10%;
    }
  
    to {
      width: 100%;
    }
  }
  @keyframes bounce{
    from {
      width: 10%;
    }
  
    to {
      width: 100%;
    }
  }

}

@media only screen and (max-width: 500px) {
  .carousel {
    min-width: 300px;
  }
  .content-carousel {
    width: 95vw;
  }

  .card {
    font-size: 18px;
  }

  .footer {
    flex-direction: column;
  }

  .pages-title {
    width: 90%;
  }

  .content-title_button {
    align-self: center;
  }
}

.logo {
  position: absolute;
  top: 2px;
  left: 3%;
  width:40px;
  /* border: 1px solid white; */
}

.blog-content{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* border: 1px solid yellow; */
}

.pages-content {
  margin-top: 70px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  /* width: 90vw; */
  /* border: 1px solid white; */
  /* align-self: center; */
}

.projects-content {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid white; */
}

.back-btn-blog {
  position: sticky;
  position: -webkit-sticky;
  top: 10%;
  left: 3%;
  margin-left: 30px;
  color: #DADADA;
  /* border: 1px solid red; */
}

.blog-entry {
  display: flex;
  flex-direction: column;
  margin: 3% 7% 3% 10%;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: lighter;
  text-align: justify;
  line-height: 1.5;
}
.blog-entry h2 {
  font-weight: lighter;
  margin-bottom: 17px;
}

/* button glow effect: */
.glow {
  -webkit-animation: glowing 5000ms infinite;
          animation: glowing 5000ms infinite;
}
@-webkit-keyframes glowing {
  0% { box-shadow: 0 0 0px #c4f9ff; }
  40% { box-shadow: 0 0 0px #18CAE6; }
  60% { box-shadow: 0 0 20px #18CAE6; }
  /* 80% { box-shadow: 0 0 20px #18CAE6; } */
  100% { box-shadow: 0 0 0px #18CAE6; }
}
@keyframes glowing {
  0% { box-shadow: 0 0 0px #c4f9ff; }
  40% { box-shadow: 0 0 0px #18CAE6; }
  60% { box-shadow: 0 0 20px #18CAE6; }
  /* 80% { box-shadow: 0 0 20px #18CAE6; } */
  100% { box-shadow: 0 0 0px #18CAE6; }
}


.box {
  display: flex;
  width: 70%;
  /* height: 50vh; */
  height: auto;
  /* font-size: 25px; */
  font-size: 20px;
  font-weight: lighter;
  text-align: center;
  /* border: 1px solid purple; */
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  /* transition: all 0.3s ease; */
  /* background-color: #212224; */
  margin: auto;
  transition: all 0.5s ease;
  margin-bottom: 60px;
}

.box-text {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 250px;
  position: relative;
  /* flex-direction: column; */
  justify-content: center;
  /* opacity: 0; */
  transition: all 0.5s ease;
  font-size: 16px;
  /* border: 1px solid blue; */
}

.text-overImg {
  position: absolute;
  top: 20%;
  right: 5%;
  width: 80%;
  color: #fff;
  font-size: 17px;
  text-align: left;
  opacity: 0;
}

.box:hover .text-overImg {
  opacity: 1;
  transition-duration: 0.4s;
}

.box:hover .box-text img {
  opacity: 0.15;
  transition-duration: 0.4s;
}

.box-in {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  opacity: 0.7;
  transition: all 0.5s ease;
  width: 50%;
  /* cursor: pointer; */
  position: relative;
}

.box-in:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition-duration: 0.4s;
  opacity: 1;
  /* font-size: 14px; */
}

.box-in > div {
  font-size: 16px;
}

/* change default color of link: */
a {
  color: #d8d8d8;
}
a:hover {
   color: #47CDDA
}

.devider hr {
  width: 70%;
  margin: auto;
  margin-bottom: 10px;
}

@media only screen and (max-width: 850px) {
  .pages-content { 
    flex-direction: column;
    align-content: center;
  }
  .box {
    width: 80%;
  }
  .box-text {
    opacity: 0.7;
    width: 90%;
  }
  .box-in {
    width: 80%;
  }
  .text-overImg {
    top: 9%;
    right: 1%;
    opacity: 1;
  }

  .box .box-text img {
    opacity: 0.15;
    transition-duration: 0.4s;
  }
  
}



/* car animation */

.car-container {
  --width: 450px;
  --height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
  width: var(--width);
  height: var(--height);
  /* border: 2px solid yellowgreen; */
  text-align: center;
  margin: auto;
}


svg .svg-elem-1 {
  stroke-dashoffset: 881.0689086914062px;
  stroke-dasharray: 881.0689086914062px;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
}

svg.active .svg-elem-1 {
  stroke-dashoffset: 0;
}

svg .svg-elem-2 {
  stroke-dashoffset: 83.81341552734375px;
  stroke-dasharray: 83.81341552734375px;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
}

svg.active .svg-elem-2 {
  stroke-dashoffset: 0;
}

svg .svg-elem-3 {
  stroke-dashoffset: 27.613113403320312px;
  stroke-dasharray: 27.613113403320312px;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s;
}

svg.active .svg-elem-3 {
  stroke-dashoffset: 0;
}

svg .svg-elem-4 {
  stroke-dashoffset: 239.59928070952677px;
  stroke-dasharray: 239.59928070952677px;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s;
}

svg.active .svg-elem-4 {
  stroke-dashoffset: 0;
}

svg .svg-elem-5 {
  stroke-dashoffset: 215.83941547042716px;
  stroke-dasharray: 215.83941547042716px;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s;
}

svg.active .svg-elem-5 {
  stroke-dashoffset: 0;
}

svg .svg-elem-6 {
  stroke-dashoffset: 239.59928070952677px;
  stroke-dasharray: 239.59928070952677px;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s;
}

svg.active .svg-elem-6 {
  stroke-dashoffset: 0;
}

svg .svg-elem-7 {
  stroke-dashoffset: 215.83941547042716px;
  stroke-dasharray: 215.83941547042716px;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s;
}

svg.active .svg-elem-7 {
  stroke-dashoffset: 0;
}

svg .svg-elem-8 {
  stroke-dashoffset: 220.83602905273438px;
  stroke-dasharray: 220.83602905273438px;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s;
}

svg.active .svg-elem-8 {
  stroke-dashoffset: 0;
}

svg .svg-elem-9 {
  stroke-dashoffset: 96.27091979980469px;
  stroke-dasharray: 96.27091979980469px;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s;
}

svg.active .svg-elem-9 {
  stroke-dashoffset: 0;
}



@media only screen and (max-width: 650px) {
  .laptopImage {
    visibility: hidden;
  }
  .text-overImg {
    font-size: 13px;
  }

  .car-container {
    --width: 300px;
  }
}
.spinner {
  position: relative;
  height: 100px;
  width: 100px;
  border: 4px solid transparent;
  border-top-color: #1976d2;
  border-left-color: #1976d2;
  border-radius: 50%;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
.spinner::before {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  content: "";
  border: 4px solid transparent;
  border-top-color: #03a9f4;
  border-left-color: #03a9f4;
  border-radius: 50%;
  -webkit-animation: spinBack 1s linear infinite;
  animation: spinBack 1s linear infinite;
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spinBack {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
}

@keyframes spinBack {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
}
