.blog-content{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* border: 1px solid yellow; */
}

.pages-content {
  margin-top: 70px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  /* width: 90vw; */
  /* border: 1px solid white; */
  /* align-self: center; */
}

.projects-content {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid white; */
}

.back-btn-blog {
  position: sticky;
  position: -webkit-sticky;
  top: 10%;
  left: 3%;
  margin-left: 30px;
  color: #DADADA;
  /* border: 1px solid red; */
}

.blog-entry {
  display: flex;
  flex-direction: column;
  margin: 3% 7% 3% 10%;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: lighter;
  text-align: justify;
  line-height: 1.5;
}
.blog-entry h2 {
  font-weight: lighter;
  margin-bottom: 17px;
}

/* button glow effect: */
.glow {
  animation: glowing 5000ms infinite;
}
@keyframes glowing {
  0% { box-shadow: 0 0 0px #c4f9ff; }
  40% { box-shadow: 0 0 0px #18CAE6; }
  60% { box-shadow: 0 0 20px #18CAE6; }
  /* 80% { box-shadow: 0 0 20px #18CAE6; } */
  100% { box-shadow: 0 0 0px #18CAE6; }
}


.box {
  display: flex;
  width: 70%;
  /* height: 50vh; */
  height: auto;
  /* font-size: 25px; */
  font-size: 20px;
  font-weight: lighter;
  text-align: center;
  /* border: 1px solid purple; */
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  /* transition: all 0.3s ease; */
  /* background-color: #212224; */
  margin: auto;
  transition: all 0.5s ease;
  margin-bottom: 60px;
}

.box-text {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 250px;
  position: relative;
  /* flex-direction: column; */
  justify-content: center;
  /* opacity: 0; */
  transition: all 0.5s ease;
  font-size: 16px;
  /* border: 1px solid blue; */
}

.text-overImg {
  position: absolute;
  top: 20%;
  right: 5%;
  width: 80%;
  color: #fff;
  font-size: 17px;
  text-align: left;
  opacity: 0;
}

.box:hover .text-overImg {
  opacity: 1;
  transition-duration: 0.4s;
}

.box:hover .box-text img {
  opacity: 0.15;
  transition-duration: 0.4s;
}

.box-in {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  opacity: 0.7;
  transition: all 0.5s ease;
  width: 50%;
  /* cursor: pointer; */
  position: relative;
}

.box-in:hover {
  transform: scale(1.05);
  transition-duration: 0.4s;
  opacity: 1;
  /* font-size: 14px; */
}

.box-in > div {
  font-size: 16px;
}

/* change default color of link: */
a {
  color: #d8d8d8;
}
a:hover {
   color: #47CDDA
}

.devider hr {
  width: 70%;
  margin: auto;
  margin-bottom: 10px;
}

@media only screen and (max-width: 850px) {
  .pages-content { 
    flex-direction: column;
    align-content: center;
  }
  .box {
    width: 80%;
  }
  .box-text {
    opacity: 0.7;
    width: 90%;
  }
  .box-in {
    width: 80%;
  }
  .text-overImg {
    top: 9%;
    right: 1%;
    opacity: 1;
  }

  .box .box-text img {
    opacity: 0.15;
    transition-duration: 0.4s;
  }
  
}



/* car animation */

.car-container {
  --width: 450px;
  --height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
  width: var(--width);
  height: var(--height);
  /* border: 2px solid yellowgreen; */
  text-align: center;
  margin: auto;
}


svg .svg-elem-1 {
  stroke-dashoffset: 881.0689086914062px;
  stroke-dasharray: 881.0689086914062px;
  -webkit-transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715)
    0s;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
}

svg.active .svg-elem-1 {
  stroke-dashoffset: 0;
}

svg .svg-elem-2 {
  stroke-dashoffset: 83.81341552734375px;
  stroke-dasharray: 83.81341552734375px;
  -webkit-transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.12s;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
}

svg.active .svg-elem-2 {
  stroke-dashoffset: 0;
}

svg .svg-elem-3 {
  stroke-dashoffset: 27.613113403320312px;
  stroke-dasharray: 27.613113403320312px;
  -webkit-transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.24s;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s;
}

svg.active .svg-elem-3 {
  stroke-dashoffset: 0;
}

svg .svg-elem-4 {
  stroke-dashoffset: 239.59928070952677px;
  stroke-dasharray: 239.59928070952677px;
  -webkit-transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.36s;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s;
}

svg.active .svg-elem-4 {
  stroke-dashoffset: 0;
}

svg .svg-elem-5 {
  stroke-dashoffset: 215.83941547042716px;
  stroke-dasharray: 215.83941547042716px;
  -webkit-transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.48s;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s;
}

svg.active .svg-elem-5 {
  stroke-dashoffset: 0;
}

svg .svg-elem-6 {
  stroke-dashoffset: 239.59928070952677px;
  stroke-dasharray: 239.59928070952677px;
  -webkit-transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.6s;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s;
}

svg.active .svg-elem-6 {
  stroke-dashoffset: 0;
}

svg .svg-elem-7 {
  stroke-dashoffset: 215.83941547042716px;
  stroke-dasharray: 215.83941547042716px;
  -webkit-transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.72s;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s;
}

svg.active .svg-elem-7 {
  stroke-dashoffset: 0;
}

svg .svg-elem-8 {
  stroke-dashoffset: 220.83602905273438px;
  stroke-dasharray: 220.83602905273438px;
  -webkit-transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.84s;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s;
}

svg.active .svg-elem-8 {
  stroke-dashoffset: 0;
}

svg .svg-elem-9 {
  stroke-dashoffset: 96.27091979980469px;
  stroke-dasharray: 96.27091979980469px;
  -webkit-transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.96s;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s;
}

svg.active .svg-elem-9 {
  stroke-dashoffset: 0;
}



@media only screen and (max-width: 650px) {
  .laptopImage {
    visibility: hidden;
  }
  .text-overImg {
    font-size: 13px;
  }

  .car-container {
    --width: 300px;
  }
}