/* .App {
  text-align: center;
  border: 1px solid blue;
  padding: 20px;
} */

.main-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #DADADA;
  min-height: 100vh;
  background-image: url('img/bigmainBG.jpg');
  /* border: 1px solid green; */
  padding-bottom: 100px;
  /* overflow: hidden; */
}

/* navbar styles */
.nav-bar {
  /* position: relative; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  /* border: 1px solid red; */
}

.nav-bar.active {
  background: #212224;
}

.pages-title {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* align-self: center; */
  /* border: 1px solid magenta; */
  width: 55%;
  margin-bottom: 10px;
  align-self: center;
  opacity: 0.8;
}

.pages-title h3 {
  font-weight: lighter;
  font-size: 23px;
  color: #DADADA;
}

.pages-title h3.current {
  color: #47CDDA;
  text-decoration: underline;
  opacity: 1;
}

.nav-bar .line {
  width: 80%;
  align-self: flex-end;
  /* transition: 0.3s;
  transition-duration: 0.4s; */



  transition: 0.3s ease-out;
  transform:scaleY(1);
  transform-origin:top
}

/* end navbar styles */

/* content styles */
.content {
  margin-top: 70px;
  display: flex;
  flex-flow: row wrap;
  width: 100vw;
  height: 60vh;
  min-height: 495px;
  justify-content: center;
  /* min-height: 60vh; */
  /* border: 1px solid blue; */
}

.back-btn {
  position: sticky;
  position: -webkit-sticky;
  top: 10%;
  left: 5%;
  color: #DADADA;
  /* display: flex; */
  /* margin-top: 60px;
  color: #DADADA;
  cursor: pointer; */
}

.content-title {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  font-weight: lighter;
  font-size: 37px;
  width: 40%;
  padding: 30px;
  margin: auto 20px;
  /* border: 1px solid lawngreen; */
}
.content-title_button {
  align-self: flex-end;
  width: 80px;
  height: 40px;
  font-size: 35px;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  border: 0.5px solid #ffffff;
  /* border-radius: 3px; */
  background-color: #212224;
  text-decoration: none;
  color: #fff;
  padding: 5px;
  padding-bottom: 10px;
  margin-top: 20px;
  margin-right: 10%;
  cursor: pointer;
  /* border: 1px solid red; */
}

.content-carousel {
  display: flex;
  flex-direction: row;
  width: 50%;
  margin: auto 10px;
  /* opacity: 0;
  transition: opacity 2s;
  opacity: 1; */
  background-color: rgba(33, 34, 36, 0.9);
  /* border: 1px solid #343538; */
  box-shadow: 4px 5px 27px -12px rgba(0,0,0,0.7);

}

.projectsName {
  font-weight: lighter;
  /* border: 1px solid brown; */
  text-align: center;
}

.carousel {
  /* border: 1px solid blue; */
  position: relative;
  /* height: 500px; */
  width: 100%;
  min-width: 400px;
}

.slide {
  /* background-color: #353639; */
  text-align: center;
  /* border: 1px solid #353639; */
  border-radius: 1%;
  cursor: grab;
}

.image {
  width: 95%;
  height: 95%;
  margin: 1.5% auto;
  opacity: 0.7;
  transition: all 0.3s linear 0.1s;
  /* border: 1px solid pink; */
}

.image:hover {
  opacity: 1;
  transition: all 0.3s linear 0.1s;
}

.title-carousel {
  text-align: center;
  font-size: 25px;
  font-weight: lighter;
  text-align: center;
  margin: 7% auto 5%;
}

.carousel-text {
  display: flex;
  justify-content: space-evenly;
  align-items: space-around;
  width: 93%;
  height: 95%;
  margin: 1.5% auto;
  padding: 20px;
  position: relative;
  opacity: 0.8;
  font-size: 23px;
  font-weight: lighter;
  text-align: justify;
  transition: all 0.2s linear 0.1s;
  /* border: 1px solid pink; */
}
.carousel-text:hover {
  opacity: 1;
  transition: all 0.3s linear 0.1s;
}

.slider-buttons_l {
  position: absolute;
  font-size: 35px;
  top: 3%;
  left: 1%;
  color: #A29E9E;
  background-color: transparent;
  border: none;
  opacity: 0.5;
  height: 95%;
  outline: none;
  /* margin-left: 5px; */
}
.slider-buttons_r {
  position: absolute;
  font-size: 35px;
  top: 3%;
  right: 1%;
  color: #A29E9E;
  background-color: transparent;
  border: none;
  opacity: 0.4;
  height: 95%;
  outline: none;
  /* margin-right: 5px; */
}

.slider-buttons_r:hover, .slider-buttons_l:hover {
  opacity: 1;
}
.dots{
  position: absolute;
  /* right: 50%; */
  width: 15%;
  border: 1px solid red;
  display: flex;
  justify-content: space-evenly;
  
}

.dots > * {
  width: 10px;
  height: 10px;
  /* border-radius: 25px; */
  /* border-radius: 50%; */
}

.content-carousel_line {
  /* border-right: 4px solid #D11414; */
  border-right: 4px solid #DF740C;
  height: 100%;
  margin: 0 auto;
}

/* .content-carousel_line-l {
  border-right: 2px solid #DF740C;
  height: 100%;
  margin: 0 0 0 auto;
}
.content-carousel_line-m {
  border-right: 1px solid #f5993d;
  height: 100%;
  margin: 0;
}
.content-carousel_line-r {
  border-right:  1px solid #DF740C;
  height: 100%;
  margin: 0 auto 0 0;
} */

/* end of content styles */

/* Footer: */

.footer {
  display: flex;
  flex-flow: row wrap;
  position: absolute;
  justify-content: space-around;
  width: 100vw;
  bottom: 0;
  min-height: 80px;
}

.footer.active {
  justify-content: flex-start;
  padding-left: 10%;
}

.footer-social {
  align-self: center;
  /* border: 1px solid yellow */
}
.icon {
  margin: 10px;
  opacity: 0.4;
  transition: 0.3s;
  color: white;
}
.icon:hover {
  transform: rotate(-11deg);
  opacity: 1;
  transition-duration: 0.4s;
  cursor: pointer;
}

.footer-name {
  align-self: center;
  font-weight: lighter;
  /* font-size: 57px; */
  font-size: 5.7rem;
  opacity: 0.8;
  /* margin-bottom: 30px; */
  /* border: 1px solid yellow; */
}

/* end of Footer */

/* media queries */

@media only screen and (min-width: 801px) {
  .animate {
    animation-duration: 1s;
    animation-name: slide;
  }
  @keyframes slide {
    from {
      height: 0%;
    }
  
    to {
      height: 100%;
    }
  }
}

@media only screen and (max-width: 850px) {
  .pages-title {
    justify-content: space-between;
  }

  .pages-title h3 {
    font-size: 18px;
    padding-right: 10px;
  }

  .back-btn {
    margin-left: 30px;
  }
  .content {
    /* height: 90vh; */
    min-height: 750px;
  }
  .content-title {
    width: 95%;
  }
  .content-carousel {
    width: 88vw;
  }
  .content-carousel_line {
    margin-top: 40px;
    height: 4px;
    border-right: 0;
    border-bottom: 4px solid #DF740C;
    width: 100%;
  }

  .footer-name {
    font-size: 3.7rem;
  }

  .image {
    width: 90%;
    height: 90%;
  }

  .animate {
    animation-duration: 1s;
    animation-name: bounce;
  }
  @keyframes bounce{
    from {
      width: 10%;
    }
  
    to {
      width: 100%;
    }
  }

}

@media only screen and (max-width: 500px) {
  .carousel {
    min-width: 300px;
  }
  .content-carousel {
    width: 95vw;
  }

  .card {
    font-size: 18px;
  }

  .footer {
    flex-direction: column;
  }

  .pages-title {
    width: 90%;
  }

  .content-title_button {
    align-self: center;
  }
}

.logo {
  position: absolute;
  top: 2px;
  left: 3%;
  width:40px;
  /* border: 1px solid white; */
}
